@tailwind base;
@tailwind components;
@tailwind utilities;


.finalimage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}